import * as React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Tab } from '@headlessui/react'
import FileIcon from '../icons/buttons/pixfort-icon-file-content-1.svg';
import ShieldIcon from '../icons/buttons/pixfort-icon-shield-lock-1.svg';
import BookmarkIcon from '../icons/buttons/pixfort-icon-bookmark-2.svg';

const Terms = ({ data, location }) => {
    

    return (
        <Layout location={location} title="Terms of use">
            <Seo title='Terms of use' />
            <div className=''>
                <main className='mx-auto mt-24 max-w-3xl px-4 sm:px-6 lg:px-8 md:mt-32'>

                    <div className='flex flex-col md:flex-row mb-4 font-semibold'>
                        <div className='py-2 md:py-4 mr-2'>
                            <Link className='justify-center md:justify-start stroke-gray-600 hover:stroke-gray-900 dark:stroke-white text-gray-600 bg-gray-100  hover:text-gray-900 dark:text-white dark:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto' to='/terms'>
                                <FileIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'>Terms of use</span>
                            </Link>
                        </div>
                        <div className='py-2 md:py-4 mr-2'>
                            <Link to='/privacy-policy' className='justify-center md:justify-start stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'>
                                <ShieldIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'>Privacy Policy</span>
                            </Link>
                        </div>
                        <div className='py-2 md:py-4'>
                            <Link to='/legal' className='justify-center md:justify-start stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'>
                                <BookmarkIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'>Legal</span>
                            </Link>
                        </div>
                    </div>

                    <div className='text-left lg:text-left text-gray-500 dark:text-gray-400 prose dark:prose-invert max-w-none'>
                        <h1 className='text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl mb-4'>
                            <span className='block xl:inline'>Terms of use</span>
                        </h1>

                        <h2>1. Terms</h2>
                        <p>
                            By accessing the website at <a href='https://pixfort.com'>https://pixfort.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not
                            agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
                        </p>
                        <h2>2. License</h2>
                        <ol type='a'>
                            <li>
                                By purchasing and using pixfort products you agree to apply the product license.
                            </li>
                            
                            <li>
                            For products sold on Envato Market, you agree to apply Envato Market Licenses, for more information about their licenses you can check this link: <a href="https://1.envato.market/mgQPVq">https://themeforest.net/licenses/standard</a>.
                            </li>
                            
                            <li>    
                                By visiting pixfort website at <a href='https://pixfort.com'>https://pixfort.com</a>, you agree not to:
                                <ol>
                                    <li>modify or copy the materials;</li>
                                    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li>attempt to decompile or reverse engineer any software contained on pixfort's website;</li>
                                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                                    <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                                </ol>
                            </li>
                            <li>
                                This license shall automatically terminate if you violate any of these restrictions or the license terms or if you commit any action we consider as a risk, in this case your access to pixfort products and services may be terminated by pixfort at any time. Upon terminating your access to our products and services or upon the termination of the license, you must destroy any downloaded materials in
                                your possession whether in electronic or printed format.
                            </li>
                        </ol>
                        <h2>3. Disclaimer</h2>
                        <ol type='a'>
                            <li>
                                The materials on pixfort's website are provided on an 'as is' basis. pixfort makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness
                                for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                            </li>
                            <li>Further, pixfort does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
                        </ol>
                        <h2>4. Limitations</h2>
                        <p>
                            In no event shall pixfort or its suppliers be liable for any damages (including, without limitation, damages for loss of data, or due to business interruption) arising out of the use or inability to use the materials on pixfort's website, even if pixfort or a pixfort
                            authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not
                            apply to you.
                        </p>
                        <h2>5. Accuracy of materials</h2>
                        <p>
                            The materials appearing on pixfort's website could include technical, typographical, or photographic errors. pixfort does not warrant that any of the materials on its website are accurate, complete or current. pixfort may make changes to the materials contained on its
                            website at any time without notice. However pixfort does not make any commitment to update the materials.
                        </p>
                        <h2>6. Links</h2>
                        <p>pixfort has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by pixfort of the site. Use of any such linked website is at the user's own risk.</p>
                        <h2>7. Modifications</h2>
                        <p>pixfort may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
                        <h2>8. Governing Law</h2>
                        <p>These terms and conditions are governed by and construed in accordance with the laws of France and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                    </div>
                </main>
            </div>
        </Layout>
    );
};

export default Terms;